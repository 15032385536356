import {
  DocumentExtensions,
  ImageExtensions,
  VideoExtensions,
} from '../../../ui/src/lib/material-preview/config';

export function getFileExtension(
  url: string,
): 'video' | 'document' | 'image' | null {
  const match = url?.match(/\.([a-zA-Z0-9]+)(?=\?|$)/);
  if (match) {
    const extension = match[1].toLowerCase();

    if (Object.values(VideoExtensions).includes(extension as VideoExtensions)) {
      return 'video';
    }
    if (
      Object.values(DocumentExtensions).includes(
        extension as DocumentExtensions,
      )
    ) {
      return 'document';
    }
    if (Object.values(ImageExtensions).includes(extension as ImageExtensions)) {
      return 'image';
    }
  }
  return null;
}
