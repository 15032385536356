// eslint-disable-next-line no-useless-escape
const urlFileNameRegEx = /(?<=\/)[^\/\?#]+(?=[^\/]*$)/;
const uuidRegex =
  /[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/;

export const getFileNameFromUrl = (
  url: string,
  customRegex?: any,
): string | any => {
  if (customRegex) {
    const match = url.match(customRegex);
    return match && match[0];
  }
  let fileName = url?.match(urlFileNameRegEx)?.[0];
  if (!fileName) {
    return url;
  }
  const uuid = fileName?.match(uuidRegex);
  if (uuid && uuid[0]) {
    fileName = fileName?.replace(uuid[0], '');
    fileName = fileName?.replace('_', '');
  }
  return fileName;
};
