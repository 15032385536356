import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertBytes',
  standalone: true,
})
export class ConvertBytesPipe implements PipeTransform {
  transform(bytes: number, unit: 'KB' | 'MB' | 'TB'): number {
    const units = {
      KB: 1024,
      MB: Math.pow(1024, 2),
      TB: Math.pow(1024, 4),
    };

    return bytes / units[unit];
  }
}
