<div class="relative">
  <a
    [routerLink]="url"
    class="cat-ai-document-card"
    routerLinkActive="cat-ai-document-card_active"
    (isActiveChange)="handleActiveChange($event)">
    <div class="cat-ai-document-card__header">
      @switch (type) {
        @case ('flowchart') {
          <mat-icon class="-rotate-90 material-icons-outlined shrink-0">
            lan
          </mat-icon>
        }
        @case ('document') {
          <mat-icon class="material-icons-outlined shrink-0">
            description
          </mat-icon>
        }
      }
      <span class="text-sm font-normal grow pr-8 truncate">{{ title }}</span>
      <popper-content #cardOptions>
        <div class="flex items-center gap-4 p-1.5">
          <button
            type="button"
            class="square outlined"
            mat-mini-fab
            color="warn"
            (click)="remove.emit()">
            <mat-icon class="material-icons-outlined">delete</mat-icon>
          </button>
          <button
            type="button"
            class="square"
            mat-mini-fab
            color="basic"
            (click)="duplicate.emit()">
            <mat-icon class="material-icons-outlined">
              control_point_duplicate
            </mat-icon>
          </button>
          @if (material) {
            <button
              type="button"
              class="square"
              mat-mini-fab
              color="basic"
              (click)="
                pin.emit({
                  materialId: material.id,
                  isPinned: material.is_pinned || false,
                })
              ">
              <mat-icon class="material-icons-outlined">push_pin</mat-icon>
            </button>
          }
          <button
            type="button"
            class="square"
            mat-mini-fab
            color="basic"
            (click)="share.emit()">
            <mat-icon class="material-icons-outlined">share</mat-icon>
          </button>
          <button
            type="button"
            class="square"
            mat-mini-fab
            color="basic"
            (click)="download.emit()">
            <mat-icon class="material-icons-outlined">file_download</mat-icon>
          </button>
        </div>
      </popper-content>
    </div>
    <div class="flex items-center justify-between">
      <div class="text-xs font-normal flex items-center gap-1">
        <span>
          {{ timestamp | dateFormat: DateFormatType.DocumentCardDate }}
        </span>
        <span class="cat-ai-document-card__divider text-gray-400">|</span>
        <span class="cat-ai-document-card__time text-gray-500">
          {{ timestamp | dateFormat: DateFormatType.DocumentCardTime }}
        </span>
      </div>

      <div class="flex items-center gap-2">
        <cat-ai-document-status [status]="status" [isInverted]="isActive" />
        @if (material?.is_pinned) {
          <mat-icon
            class="!size-3 text-[12px]"
            [ngClass]="{
              'text-gray-500': !isActive,
              'text-gray-100': isActive,
            }">
            push_pin
          </mat-icon>
        }
      </div>
    </div>
  </a>
  @if (showOptions) {
    <button
      type="button"
      mat-icon-button
      class="!size-6 !p-0 !absolute top-3 right-4"
      [ngClass]="{
        '!text-white': isActive,
      }"
      [popper]="cardOptions"
      [popperTrigger]="PopperTriggers.click">
      <mat-icon>more_horiz</mat-icon>
    </button>
  }
</div>
