import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Material } from '@cat-ai-us-fe/api';
import { DateFormatPipe, DateFormatType } from '@cat-ai-us-fe/shared/util';
import { NgxPopperjsModule, NgxPopperjsTriggers } from 'ngx-popperjs';
import { DocumentStatusComponent } from '../document-status/document-status.component';
import { NgClass } from '@angular/common';

type DocumentCardType = 'flowchart' | 'document';

@Component({
  selector: 'cat-ai-document-card',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    MatIconModule,
    MatButtonModule,
    DateFormatPipe,
    NgxPopperjsModule,
    DocumentStatusComponent,
    NgClass,
  ],
  templateUrl: './document-card.component.html',
})
export class DocumentCardComponent {
  @Input({ required: true }) type: DocumentCardType = 'document';
  @Input({ required: true }) title = '';
  @Input({ required: true }) timestamp = '';
  @Input({ required: true }) status: Material.StatusEnum =
    Material.StatusEnum.Created;
  @Input({ required: true }) url = '';
  @Input() material: Material | null | any = null;
  @Input() showOptions = true;

  @Output() remove = new EventEmitter();
  @Output() duplicate = new EventEmitter();
  @Output() pin = new EventEmitter<{ materialId: number; isPinned: boolean }>();
  @Output() share = new EventEmitter();
  @Output() download = new EventEmitter();

  protected readonly DateFormatType = DateFormatType;
  protected readonly PopperTriggers = NgxPopperjsTriggers;
  protected isActive = false;

  handleActiveChange(value: boolean) {
    this.isActive = value;
  }
}
