<mat-dialog-content class="mat-typography">
  <div class="mb-2 flex items-center justify-between">
    <p class="text-m font-semibold">{{ data.modalTitle }}</p>
    <a class="cursor-pointer" mat-dialog-close>
      <mat-icon class="material-icons-outlined shrink-0 text-gray-700">
        close
      </mat-icon>
    </a>
  </div>

  <a class="cat-ai-document-card">
    <div class="cat-ai-document-card__header">
      <mat-icon class="-rotate-90 material-icons-outlined shrink-0">
        lan
      </mat-icon>
      <span class="text-sm font-normal grow pr-8">{{ data.title }}</span>
    </div>
    <div class="flex items-center justify-between">
      <div class="text-xs font-normal flex items-center gap-1">
        <span>
          {{ data.timestamp | dateFormat: DateFormatType.DocumentCardDate }}
        </span>
        <span class="cat-ai-document-card__divider text-gray-400">|</span>
        <span class="cat-ai-document-card__time text-gray-500">
          {{ data.timestamp | dateFormat: DateFormatType.DocumentCardTime }}
        </span>
      </div>

      <cat-ai-document-status [status]="data.status" [isInverted]="isActive" />
    </div>
  </a>
  <div class="flex items-center mt-[24px]">
    <mat-checkbox
      class="example-margin mr-[10px]"
      [checked]="isActive"
      (change)="isActive = $event.checked"></mat-checkbox>
    <span class="text-m font-medium">Keep chat history</span>
  </div>
  <div class="control-modal-footer flex justify-between mt-[32px]">
    <button
      class="w-full mr-[12px]"
      mat-flat-button
      color="basic"
      type="button"
      mat-dialog-close>
      Cancel
    </button>
    <button
      class="w-full"
      mat-flat-button
      color="accent"
      (click)="duplicate()"
      [disabled]="isLoading">
      Duplicate
    </button>
  </div>
</mat-dialog-content>
