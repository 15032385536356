import * as i0 from '@angular/core';
import { EventEmitter, Component, ViewEncapsulation, ChangeDetectionStrategy, ViewChild, HostListener, InjectionToken, Directive, Inject, Input, Output, NgModule } from '@angular/core';
import { NgStyle, NgClass, NgIf, CommonModule } from '@angular/common';
import { autoUpdate, flip, shift, limitShift, offset, arrow, autoPlacement, computePosition } from '@floating-ui/dom';
import { Subject, fromEvent, takeUntil, timer } from 'rxjs';
const _c0 = ["popperViewRef"];
function NgxPopperjsContentComponent_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵprojection(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("innerHTML", ctx_r1.text, i0.ɵɵsanitizeHtml);
  }
}
function NgxPopperjsContentComponent_div_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵprojection(1, 1);
    i0.ɵɵelementEnd();
  }
}
const _c1 = ["*", "*"];
var NgxPopperjsTriggers;
(function (NgxPopperjsTriggers) {
  NgxPopperjsTriggers["click"] = "click";
  NgxPopperjsTriggers["hover"] = "hover";
  NgxPopperjsTriggers["mousedown"] = "mousedown";
  NgxPopperjsTriggers["none"] = "none";
})(NgxPopperjsTriggers || (NgxPopperjsTriggers = {}));
class NgxPopperjsContentComponent {
  elRef;
  _viewRef;
  _changeDetectorRef;
  static nextId = 0;
  ariaHidden;
  arrowColor = null;
  displayType;
  id = `ngx_poppperjs_${++NgxPopperjsContentComponent.nextId}`;
  isMouseOver = !1;
  onHidden = new EventEmitter();
  onUpdate;
  opacity;
  popperInstance;
  popperOptions = {
    disableAnimation: false,
    disableDefaultStyling: false,
    boundariesElement: "",
    trigger: NgxPopperjsTriggers.hover,
    positionFixed: false,
    appendToBody: false,
    popperModifiers: []
  };
  popperViewRef;
  referenceObject;
  state;
  text;
  _destroy$ = new Subject();
  _resizeCtrl$ = new Subject();
  _styleId = `${this.id}_style`;
  constructor(elRef, _viewRef, _changeDetectorRef) {
    this.elRef = elRef;
    this._viewRef = _viewRef;
    this._changeDetectorRef = _changeDetectorRef;
    this._toggleVisibility(!1);
  }
  clean() {
    this.toggleVisibility(false);
    if (!this.popperInstance) {
      return;
    }
    this.popperInstance();
  }
  extractAppliedClassListExpr(classList = []) {
    const klassList = Array.isArray(classList) ? classList : typeof classList === typeof "" ? classList.replace(/ /, "").split(",") : [];
    return klassList.reduce((acc, klass) => {
      acc[klass] = !0;
      return acc;
    }, {});
  }
  hide() {
    if (this.popperInstance) {
      this.popperInstance();
    }
    this.toggleVisibility(!1);
    this.onHidden.emit();
  }
  ngOnDestroy() {
    this._destroy$.next();
    this.clean();
    if (this.popperOptions.appendTo && this.elRef && this.elRef.nativeElement && this.elRef.nativeElement.parentNode) {
      this._viewRef.detach();
      this.elRef.nativeElement.parentNode.removeChild(this.elRef.nativeElement);
    }
  }
  onDocumentResize() {
    this.update();
  }
  onMouseOver() {
    this.isMouseOver = true;
  }
  show() {
    if (!this.referenceObject) {
      return;
    }
    this._resizeCtrl$.next();
    this._determineArrowColor();
    this.popperInstance = autoUpdate(this.referenceObject, this.popperViewRef.nativeElement, () => {
      this._computePosition();
    });
    fromEvent(document, "resize").pipe(takeUntil(this._resizeCtrl$), takeUntil(this._destroy$)).subscribe({
      next: () => this.onDocumentResize()
    });
  }
  showOnLeave() {
    this.isMouseOver = false;
    if (this.popperOptions.trigger !== NgxPopperjsTriggers.hover && !this.popperOptions.hideOnMouseLeave) {
      return;
    }
    this.hide();
  }
  // Toggle visibility and detect changes - Run only after ngOnInit!
  toggleVisibility(state) {
    this._toggleVisibility(state);
    // tslint:disable-next-line:no-string-literal
    if (!this._changeDetectorRef["destroyed"]) {
      this._changeDetectorRef.detectChanges();
    }
  }
  update() {
    this._computePosition();
  }
  _computePosition() {
    const appendToParent = this.popperOptions.appendTo && document.querySelector(this.popperOptions.appendTo);
    if (appendToParent && this.elRef.nativeElement.parentNode !== appendToParent) {
      this.elRef.nativeElement.parentNode && this.elRef.nativeElement.parentNode.removeChild(this.elRef.nativeElement);
      appendToParent.appendChild(this.elRef.nativeElement);
    }
    const arrowElement = this.elRef.nativeElement.querySelector(".ngxp__arrow");
    const arrowLen = arrowElement.offsetWidth;
    // Get half the arrow box's hypotenuse length
    const floatingOffset = Math.sqrt(2 * arrowLen ** 2) / 2;
    const boundaryMiddleware = [];
    if (this.popperOptions.flip) {
      boundaryMiddleware.push(flip());
    }
    if (this.popperOptions.preventOverflow) {
      boundaryMiddleware.push(shift({
        limiter: limitShift()
      }));
    }
    const popperOptions = {
      placement: this.popperOptions.placement,
      strategy: this.popperOptions.positionFixed ? "fixed" : "absolute",
      middleware: [offset(floatingOffset), ...boundaryMiddleware, arrow({
        element: arrowElement,
        padding: 4
      })]
    };
    if (!this.popperOptions.preventOverflow && !popperOptions.placement) {
      const boundariesElement = this.popperOptions.boundariesElement && document.querySelector(this.popperOptions.boundariesElement);
      popperOptions.middleware.push(autoPlacement({
        boundary: boundariesElement
      }));
    }
    computePosition(this.referenceObject, this.popperViewRef.nativeElement, popperOptions).then(({
      middlewareData,
      x,
      y,
      placement
    }) => {
      const side = placement.split("-")[0];
      this.popperViewRef.nativeElement.setAttribute("data-popper-placement", side);
      if (middlewareData.arrow) {
        const staticSide = {
          top: "bottom",
          right: "left",
          bottom: "top",
          left: "right"
        }[side];
        Object.assign(arrowElement.style, {
          left: middlewareData.arrow.x != null ? `${middlewareData.arrow.x}px` : "",
          top: middlewareData.arrow.y != null ? `${middlewareData.arrow.y}px` : "",
          [staticSide]: `${-arrowLen / 2}px`
        });
      }
      Object.assign(this.popperViewRef.nativeElement.style, {
        left: `${x}px`,
        top: `${y}px`
      });
      this.toggleVisibility(!0);
      this.onUpdate?.();
    });
  }
  _createArrowSelector() {
    return `div#${this.id}.ngxp__container > .ngxp__arrow.ngxp__force-arrow`;
  }
  _determineArrowColor() {
    if (!this.popperOptions.styles || this.arrowColor) {
      return !1;
    }
    const ruleValue = this.popperOptions.styles["background-color"] || this.popperOptions.styles.backgroundColor;
    if (this.arrowColor === ruleValue) {
      return !1;
    }
    this.arrowColor = ruleValue;
    let $style = document.querySelector(`#${this._styleId}`);
    const styleContent = this.arrowColor ? `${this._createArrowSelector()}:before { background-color: ${this.arrowColor}; }` : "";
    if (!$style) {
      $style = document.createElement("style");
      $style.id = this._styleId;
      $style.setAttribute("type", "text/css");
      document.head.appendChild($style);
    }
    // tslint:disable-next-line:no-string-literal
    if ($style["styleSheet"]) {
      // tslint:disable-next-line:no-string-literal
      $style["styleSheet"].cssText = styleContent;
      // This is required for IE8 and below.
    } else {
      $style.innerHTML = styleContent;
    }
  }
  _toggleVisibility(state) {
    this.displayType = ["none", "block"][+state];
    this.opacity = +state;
    this.ariaHidden = `${!state}`;
    this.state = state;
  }
  /** @nocollapse */
  static ɵfac = function NgxPopperjsContentComponent_Factory(t) {
    return new (t || NgxPopperjsContentComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: NgxPopperjsContentComponent,
    selectors: [["popper-content"]],
    viewQuery: function NgxPopperjsContentComponent_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 7);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.popperViewRef = _t.first);
      }
    },
    hostBindings: function NgxPopperjsContentComponent_HostBindings(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵlistener("mouseover", function NgxPopperjsContentComponent_mouseover_HostBindingHandler() {
          return ctx.onMouseOver();
        })("mouseleave", function NgxPopperjsContentComponent_mouseleave_HostBindingHandler() {
          return ctx.showOnLeave();
        });
      }
    },
    exportAs: ["ngxPopperjsContent"],
    standalone: true,
    features: [i0.ɵɵStandaloneFeature],
    ngContentSelectors: _c1,
    decls: 5,
    vars: 19,
    consts: [[3, "ngStyle", "ngClass"], ["popperViewRef", ""], ["class", "ngxp__inner", 3, "innerHTML", 4, "ngIf"], ["class", "ngxp__inner", 4, "ngIf"], [1, "ngxp__arrow", 3, "ngClass"], [1, "ngxp__inner", 3, "innerHTML"], [1, "ngxp__inner"]],
    template: function NgxPopperjsContentComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef(_c1);
        i0.ɵɵelementStart(0, "div", 0, 1);
        i0.ɵɵtemplate(2, NgxPopperjsContentComponent_div_2_Template, 2, 1, "div", 2)(3, NgxPopperjsContentComponent_div_3_Template, 2, 0, "div", 3);
        i0.ɵɵelement(4, "div", 4);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵstyleProp("display", ctx.displayType)("opacity", ctx.opacity);
        i0.ɵɵclassProp("ngxp__container", !ctx.popperOptions.disableDefaultStyling)("ngxp__animation", !ctx.popperOptions.disableAnimation);
        i0.ɵɵproperty("ngStyle", ctx.popperOptions.styles)("ngClass", ctx.extractAppliedClassListExpr(ctx.popperOptions.applyClass));
        i0.ɵɵattribute("id", ctx.id)("aria-hidden", ctx.ariaHidden)("aria-describedby", ctx.popperOptions.ariaDescribe || null)("role", ctx.popperOptions.ariaRole);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.text);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", !ctx.text);
        i0.ɵɵadvance();
        i0.ɵɵclassProp("ngxp__force-arrow", ctx.arrowColor);
        i0.ɵɵproperty("ngClass", ctx.extractAppliedClassListExpr(ctx.popperOptions.applyArrowClass));
      }
    },
    dependencies: [NgStyle, NgClass, NgIf],
    styles: ["popper-content{position:relative;display:block}.ngxp__container{display:none;position:absolute;border-radius:3px;border:1px solid grey;box-shadow:0 0 2px #00000080;padding:10px}.ngxp__container.ngxp__animation{animation:ngxp-fadeIn .15s ease-out}.ngxp__container>.ngxp__arrow{position:absolute;width:10px;height:10px;z-index:-1;transform:rotate(45deg);background-color:red}.ngxp__container[data-popper-placement^=top]>.ngxp__arrow{bottom:-5px}.ngxp__container[data-popper-placement^=bottom]>.ngxp__arrow{top:-5px}.ngxp__container[data-popper-placement^=left]>.ngxp__arrow{right:-5px}.ngxp__container[data-popper-placement^=right]>.ngxp__arrow{left:-5px}@keyframes ngxp-fadeIn{0%{display:none;opacity:0}1%{display:block;opacity:0}to{display:block;opacity:1}}\n"],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPopperjsContentComponent, [{
    type: Component,
    args: [{
      selector: "popper-content",
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      exportAs: "ngxPopperjsContent",
      standalone: true,
      imports: [NgStyle, NgClass, NgIf],
      template: "<div #popperViewRef\r\n     [attr.id]=\"id\"\r\n     [class.ngxp__container]=\"!popperOptions.disableDefaultStyling\"\r\n     [class.ngxp__animation]=\"!popperOptions.disableAnimation\"\r\n     [style.display]=\"displayType\"\r\n     [style.opacity]=\"opacity\"\r\n     [ngStyle]=\"popperOptions.styles\"\r\n     [ngClass]=\"extractAppliedClassListExpr(popperOptions.applyClass)\"\r\n     attr.aria-hidden=\"{{ariaHidden}}\"\r\n     [attr.aria-describedby]=\"popperOptions.ariaDescribe || null\"\r\n     attr.role=\"{{popperOptions.ariaRole}}\">\r\n    <div *ngIf=\"text\"\r\n         class=\"ngxp__inner\"\r\n         [innerHTML]=\"text\">\r\n        <ng-content></ng-content>\r\n    </div>\r\n    <div *ngIf=\"!text\"\r\n         class=\"ngxp__inner\">\r\n        <ng-content></ng-content>\r\n    </div>\r\n    <div class=\"ngxp__arrow\"\r\n         [class.ngxp__force-arrow]=\"arrowColor\"\r\n         [ngClass]=\"extractAppliedClassListExpr(popperOptions.applyArrowClass)\"></div>\r\n\r\n</div>\r\n",
      styles: ["popper-content{position:relative;display:block}.ngxp__container{display:none;position:absolute;border-radius:3px;border:1px solid grey;box-shadow:0 0 2px #00000080;padding:10px}.ngxp__container.ngxp__animation{animation:ngxp-fadeIn .15s ease-out}.ngxp__container>.ngxp__arrow{position:absolute;width:10px;height:10px;z-index:-1;transform:rotate(45deg);background-color:red}.ngxp__container[data-popper-placement^=top]>.ngxp__arrow{bottom:-5px}.ngxp__container[data-popper-placement^=bottom]>.ngxp__arrow{top:-5px}.ngxp__container[data-popper-placement^=left]>.ngxp__arrow{right:-5px}.ngxp__container[data-popper-placement^=right]>.ngxp__arrow{left:-5px}@keyframes ngxp-fadeIn{0%{display:none;opacity:0}1%{display:block;opacity:0}to{display:block;opacity:1}}\n"]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.ViewContainerRef
  }, {
    type: i0.ChangeDetectorRef
  }], {
    popperViewRef: [{
      type: ViewChild,
      args: ["popperViewRef", {
        static: !0
      }]
    }],
    onMouseOver: [{
      type: HostListener,
      args: ["mouseover"]
    }],
    showOnLeave: [{
      type: HostListener,
      args: ["mouseleave"]
    }]
  });
})();
const NGX_POPPERJS_DEFAULTS = new InjectionToken("NGX_POPPERJS_DEFAULTS");

/**
 * @private
 */
class NgxPopperjsUtils {
  /** Coerces a data-bound value (typically a string) to a boolean. */
  static coerceBooleanProperty(value) {
    return value != null && `${value}` !== "false";
  }
}

// tslint:disable:no-input-rename
class NgxPopperjsDirective {
  _changeDetectorRef;
  _elementRef;
  _vcr;
  _popperDefaults;
  static baseOptions = {
    showDelay: 0,
    hideOnClickOutside: true,
    hideOnMouseLeave: false,
    hideOnScroll: false,
    appendTo: undefined,
    ariaRole: "popper",
    ariaDescribe: "",
    styles: {},
    trigger: NgxPopperjsTriggers.click
  };
  set applyClass(newValue) {
    if (newValue === this._applyClass) {
      return;
    }
    this._applyClass = newValue;
    this._checkExisting("applyClass", newValue);
  }
  get applyClass() {
    return this._applyClass;
  }
  set hideOnClickOutside(newValue) {
    this._hideOnClickOutside = NgxPopperjsUtils.coerceBooleanProperty(newValue);
  }
  get hideOnClickOutside() {
    return this._hideOnClickOutside;
  }
  set content(newValue) {
    if (newValue === this._content) {
      return;
    }
    this._content = newValue;
    if (this._popperContent) {
      if (typeof newValue === "string") {
        this._popperContent.text = newValue;
      } else {
        this._popperContent = newValue;
      }
    }
  }
  get content() {
    return this._content;
  }
  set flip(newValue) {
    this._flip = NgxPopperjsUtils.coerceBooleanProperty(newValue);
    this._checkExisting("preventOverflow", this._preventOverflow);
  }
  get flip() {
    return this._flip;
  }
  set placement(newValue) {
    this._placement = newValue;
    this._checkExisting("placement", newValue);
  }
  get placement() {
    return this._placement;
  }
  set preventOverflow(newValue) {
    this._preventOverflow = NgxPopperjsUtils.coerceBooleanProperty(newValue);
    this._checkExisting("preventOverflow", this._preventOverflow);
  }
  get preventOverflow() {
    return this._preventOverflow;
  }
  set popperApplyArrowClass(newValue) {
    if (newValue === this._applyArrowClass) {
      return;
    }
    this._applyArrowClass = newValue;
    if (this._popperContent) {
      this._popperContent.popperOptions.applyArrowClass = newValue;
      if (!this._shown) {
        return;
      }
      this._popperContent.update();
    }
  }
  get popperApplyArrowClass() {
    return this._applyArrowClass;
  }
  set disabled(newValue) {
    if (newValue === this._disabled) {
      return;
    }
    this._disabled = !!newValue;
    if (this._shown) {
      this.hide();
    }
  }
  get disabled() {
    return this._disabled;
  }
  ariaDescribe;
  ariaRole;
  boundariesElement;
  disableAnimation;
  disableStyle;
  hideOnMouseLeave;
  hideOnScroll;
  hideTimeout = 0;
  popperAppendTo;
  popperOnHidden = new EventEmitter();
  popperOnShown = new EventEmitter();
  popperOnUpdate = new EventEmitter();
  positionFixed;
  showDelay;
  showOnStart;
  showTrigger;
  styles;
  targetElement;
  timeoutAfterShow = 0;
  _applyArrowClass;
  _applyClass;
  _content;
  _destroy$ = new Subject();
  _disabled;
  _flip = !0;
  _globalEventListenersCtrl$ = new Subject();
  _hideOnClickOutside = true;
  _placement;
  _popperContent;
  _popperContentClass = NgxPopperjsContentComponent;
  _popperContentRef;
  _preventOverflow = !0;
  _scheduledHideTimeoutCtrl$ = new Subject();
  _scheduledShowTimeoutCtrl$ = new Subject();
  _shown = false;
  constructor(_changeDetectorRef, _elementRef, _vcr, _popperDefaults = {}) {
    this._changeDetectorRef = _changeDetectorRef;
    this._elementRef = _elementRef;
    this._vcr = _vcr;
    this._popperDefaults = _popperDefaults;
    NgxPopperjsDirective.baseOptions = {
      ...NgxPopperjsDirective.baseOptions,
      ...this._popperDefaults
    };
  }
  static assignDefined(target, ...sources) {
    for (const source of sources) {
      for (const key of Object.keys(source)) {
        const val = source[key];
        if (val !== undefined) {
          target[key] = val;
        }
      }
    }
    return target;
  }
  applyTriggerListeners() {
    switch (this.showTrigger) {
      case NgxPopperjsTriggers.click:
        this._addListener("click", this.toggle.bind(this));
        break;
      case NgxPopperjsTriggers.mousedown:
        this._addListener("mousedown", this.toggle.bind(this));
        break;
      case NgxPopperjsTriggers.hover:
        this._addListener("mouseenter", this.scheduledShow.bind(this, this.showDelay));
        ["touchend", "touchcancel", "mouseleave"].forEach(eventName => {
          this._addListener(eventName, this.scheduledHide.bind(this, null, this.hideTimeout));
        });
        break;
    }
    if (this.showTrigger !== NgxPopperjsTriggers.hover && this.hideOnMouseLeave) {
      ["touchend", "touchcancel", "mouseleave"].forEach(eventName => {
        this._addListener(eventName, this.scheduledHide.bind(this, null, this.hideTimeout));
      });
    }
  }
  getRefElement() {
    return this.targetElement || this._elementRef.nativeElement;
  }
  hide() {
    if (this.disabled) {
      return;
    }
    if (!this._shown) {
      this._scheduledShowTimeoutCtrl$.next();
      return;
    }
    this._shown = false;
    if (this._popperContentRef) {
      this._popperContentRef.instance.hide();
    } else {
      this._popperContent.hide();
    }
    this.popperOnHidden.emit(this);
    this._globalEventListenersCtrl$.next();
  }
  hideOnClickOutsideHandler($event) {
    if (this.disabled || !this.hideOnClickOutside || $event.target === this._popperContent.elRef.nativeElement || this._popperContent.elRef.nativeElement.contains($event.target)) {
      return;
    }
    this.scheduledHide($event, this.hideTimeout);
  }
  hideOnScrollHandler($event) {
    if (this.disabled || !this.hideOnScroll) {
      return;
    }
    this.scheduledHide($event, this.hideTimeout);
  }
  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
    this._popperContent && this._popperContent.clean();
  }
  ngOnInit() {
    if (typeof this.content === "string") {
      this._popperContent = this._constructContent();
      this._popperContent.text = this.content;
    } else if (typeof this.content === typeof void 0) {
      this._popperContent = this._constructContent();
      this._popperContent.text = "";
    } else {
      this._popperContent = this.content;
    }
    const popperRef = this._popperContent;
    popperRef.referenceObject = this.getRefElement();
    this._setContentProperties(popperRef);
    this._setDefaults();
    this.applyTriggerListeners();
    if (this.showOnStart) {
      this.scheduledShow();
    }
  }
  scheduledHide($event = null, delay = this.hideTimeout) {
    if (this.disabled) {
      return;
    }
    this._scheduledShowTimeoutCtrl$.next();
    timer(delay).pipe(takeUntil(this._scheduledHideTimeoutCtrl$), takeUntil(this._destroy$)).subscribe({
      next: () => {
        // TODO: check
        const toElement = $event ? $event.toElement : null;
        const popperContentView = this._popperContent.popperViewRef ? this._popperContent.popperViewRef.nativeElement : false;
        if (!popperContentView || popperContentView === toElement || popperContentView.contains(toElement) || this.content && this.content.isMouseOver) {
          return;
        }
        this.hide();
        this._applyChanges();
      }
    });
  }
  scheduledShow(delay = this.showDelay) {
    if (this.disabled) {
      return;
    }
    this._scheduledHideTimeoutCtrl$.next();
    timer(delay).pipe(takeUntil(this._scheduledShowTimeoutCtrl$), takeUntil(this._destroy$)).subscribe({
      next: () => {
        this.show();
        this._applyChanges();
      }
    });
  }
  show() {
    if (this._shown) {
      this._scheduledHideTimeoutCtrl$.next();
      return;
    }
    this._shown = true;
    const popperRef = this._popperContent;
    const element = this.getRefElement();
    if (popperRef.referenceObject !== element) {
      popperRef.referenceObject = element;
    }
    this._setContentProperties(popperRef);
    popperRef.show();
    this.popperOnShown.emit(this);
    if (this.timeoutAfterShow > 0) {
      this.scheduledHide(null, this.timeoutAfterShow);
    }
    fromEvent(document, "click").pipe(takeUntil(this._globalEventListenersCtrl$), takeUntil(this._destroy$)).subscribe({
      next: e => this.hideOnClickOutsideHandler(e)
    });
    fromEvent(this._getScrollParent(this.getRefElement()), "scroll").pipe(takeUntil(this._globalEventListenersCtrl$), takeUntil(this._destroy$)).subscribe({
      next: e => {
        this.hideOnScrollHandler(e);
      }
    });
  }
  toggle() {
    if (this.disabled) {
      return;
    }
    this._shown ? this.scheduledHide(null, this.hideTimeout) : this.scheduledShow();
  }
  _addListener(eventName, cb) {
    fromEvent(this._elementRef.nativeElement, eventName).pipe(takeUntil(this._destroy$)).subscribe({
      next: cb
    });
  }
  _applyChanges() {
    this._changeDetectorRef.markForCheck();
    this._changeDetectorRef.detectChanges();
  }
  _checkExisting(key, newValue) {
    if (this._popperContent) {
      this._popperContent.popperOptions[key] = newValue;
      if (!this._shown) {
        return;
      }
      this._popperContent.update();
    }
  }
  _constructContent() {
    this._popperContentRef = this._vcr.createComponent(this._popperContentClass);
    return this._popperContentRef.instance;
  }
  _getScrollParent(node) {
    const isElement = node instanceof HTMLElement;
    const overflowY = isElement && window.getComputedStyle(node).overflowY;
    const isScrollable = overflowY !== "visible" && overflowY !== "hidden";
    if (!node) {
      return null;
    } else if (isScrollable && node.scrollHeight > node.clientHeight) {
      return node;
    }
    return this._getScrollParent(node.parentNode) || document;
  }
  _onPopperUpdate(event) {
    this.popperOnUpdate.emit(event);
  }
  _setContentProperties(popperRef) {
    popperRef.popperOptions = NgxPopperjsDirective.assignDefined(popperRef.popperOptions, NgxPopperjsDirective.baseOptions, {
      showDelay: this.showDelay,
      disableAnimation: this.disableAnimation,
      disableDefaultStyling: this.disableStyle,
      placement: this.placement,
      boundariesElement: this.boundariesElement,
      trigger: this.showTrigger,
      positionFixed: this.positionFixed,
      ariaDescribe: this.ariaDescribe,
      ariaRole: this.ariaRole,
      applyClass: this.applyClass,
      applyArrowClass: this.popperApplyArrowClass,
      hideOnMouseLeave: this.hideOnMouseLeave,
      styles: this.styles,
      appendTo: this.popperAppendTo,
      preventOverflow: this.preventOverflow
    });
    popperRef.onUpdate = this._onPopperUpdate.bind(this);
    popperRef.onHidden.pipe(takeUntil(this._destroy$)).subscribe(this.hide.bind(this));
  }
  _setDefaults() {
    ["showDelay", "hideOnScroll", "hideOnMouseLeave", "hideOnClickOutside", "ariaRole", "ariaDescribe"].forEach(key => {
      this[key] = this[key] === void 0 ? NgxPopperjsDirective.baseOptions[key] : this[key];
    });
    this.showTrigger = this.showTrigger || NgxPopperjsDirective.baseOptions.trigger;
    this.styles = this.styles === void 0 ? {
      ...NgxPopperjsDirective.baseOptions.styles
    } : this.styles;
  }
  /** @nocollapse */
  static ɵfac = function NgxPopperjsDirective_Factory(t) {
    return new (t || NgxPopperjsDirective)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(NGX_POPPERJS_DEFAULTS));
  };
  /** @nocollapse */
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: NgxPopperjsDirective,
    selectors: [["", "popper", ""]],
    inputs: {
      applyClass: [i0.ɵɵInputFlags.None, "popperApplyClass", "applyClass"],
      hideOnClickOutside: [i0.ɵɵInputFlags.None, "popperHideOnClickOutside", "hideOnClickOutside"],
      content: [i0.ɵɵInputFlags.None, "popper", "content"],
      flip: [i0.ɵɵInputFlags.None, "popperFlip", "flip"],
      placement: [i0.ɵɵInputFlags.None, "popperPlacement", "placement"],
      preventOverflow: [i0.ɵɵInputFlags.None, "popperPreventOverflow", "preventOverflow"],
      popperApplyArrowClass: "popperApplyArrowClass",
      disabled: [i0.ɵɵInputFlags.None, "popperDisabled", "disabled"],
      ariaDescribe: [i0.ɵɵInputFlags.None, "popperAriaDescribeBy", "ariaDescribe"],
      ariaRole: [i0.ɵɵInputFlags.None, "popperAriaRole", "ariaRole"],
      boundariesElement: [i0.ɵɵInputFlags.None, "popperBoundaries", "boundariesElement"],
      disableAnimation: [i0.ɵɵInputFlags.None, "popperDisableAnimation", "disableAnimation"],
      disableStyle: [i0.ɵɵInputFlags.None, "popperDisableStyle", "disableStyle"],
      hideOnMouseLeave: [i0.ɵɵInputFlags.None, "popperHideOnMouseLeave", "hideOnMouseLeave"],
      hideOnScroll: [i0.ɵɵInputFlags.None, "popperHideOnScroll", "hideOnScroll"],
      hideTimeout: [i0.ɵɵInputFlags.None, "popperTimeout", "hideTimeout"],
      popperAppendTo: "popperAppendTo",
      positionFixed: [i0.ɵɵInputFlags.None, "popperPositionFixed", "positionFixed"],
      showDelay: [i0.ɵɵInputFlags.None, "popperDelay", "showDelay"],
      showOnStart: [i0.ɵɵInputFlags.None, "popperShowOnStart", "showOnStart"],
      showTrigger: [i0.ɵɵInputFlags.None, "popperTrigger", "showTrigger"],
      styles: [i0.ɵɵInputFlags.None, "popperStyles", "styles"],
      targetElement: [i0.ɵɵInputFlags.None, "popperTarget", "targetElement"],
      timeoutAfterShow: [i0.ɵɵInputFlags.None, "popperTimeoutAfterShow", "timeoutAfterShow"]
    },
    outputs: {
      popperOnHidden: "popperOnHidden",
      popperOnShown: "popperOnShown",
      popperOnUpdate: "popperOnUpdate"
    },
    exportAs: ["popper"],
    standalone: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPopperjsDirective, [{
    type: Directive,
    args: [{
      // tslint:disable-next-line:directive-selector
      selector: "[popper]",
      exportAs: "popper",
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.ViewContainerRef
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NGX_POPPERJS_DEFAULTS]
    }]
  }], {
    applyClass: [{
      type: Input,
      args: ["popperApplyClass"]
    }],
    hideOnClickOutside: [{
      type: Input,
      args: ["popperHideOnClickOutside"]
    }],
    content: [{
      type: Input,
      args: ["popper"]
    }],
    flip: [{
      type: Input,
      args: ["popperFlip"]
    }],
    placement: [{
      type: Input,
      args: ["popperPlacement"]
    }],
    preventOverflow: [{
      type: Input,
      args: ["popperPreventOverflow"]
    }],
    popperApplyArrowClass: [{
      type: Input
    }],
    disabled: [{
      type: Input,
      args: ["popperDisabled"]
    }],
    ariaDescribe: [{
      type: Input,
      args: ["popperAriaDescribeBy"]
    }],
    ariaRole: [{
      type: Input,
      args: ["popperAriaRole"]
    }],
    boundariesElement: [{
      type: Input,
      args: ["popperBoundaries"]
    }],
    disableAnimation: [{
      type: Input,
      args: ["popperDisableAnimation"]
    }],
    disableStyle: [{
      type: Input,
      args: ["popperDisableStyle"]
    }],
    hideOnMouseLeave: [{
      type: Input,
      args: ["popperHideOnMouseLeave"]
    }],
    hideOnScroll: [{
      type: Input,
      args: ["popperHideOnScroll"]
    }],
    hideTimeout: [{
      type: Input,
      args: ["popperTimeout"]
    }],
    popperAppendTo: [{
      type: Input
    }],
    popperOnHidden: [{
      type: Output
    }],
    popperOnShown: [{
      type: Output
    }],
    popperOnUpdate: [{
      type: Output
    }],
    positionFixed: [{
      type: Input,
      args: ["popperPositionFixed"]
    }],
    showDelay: [{
      type: Input,
      args: ["popperDelay"]
    }],
    showOnStart: [{
      type: Input,
      args: ["popperShowOnStart"]
    }],
    showTrigger: [{
      type: Input,
      args: ["popperTrigger"]
    }],
    styles: [{
      type: Input,
      args: ["popperStyles"]
    }],
    targetElement: [{
      type: Input,
      args: ["popperTarget"]
    }],
    timeoutAfterShow: [{
      type: Input,
      args: ["popperTimeoutAfterShow"]
    }]
  });
})();

// tslint:disable:no-input-rename
class NgxPopperjsLooseDirective extends NgxPopperjsDirective {
  set popperLoose(newValue) {
    this.content = newValue;
  }
  set popperLoosePlacement(newValue) {
    this.placement = newValue;
  }
  set popperLooseTrigger(newValue) {
    this.showTrigger = newValue;
  }
  constructor(changeDetectorRef, elementRef, vcr, popperDefaults = {}) {
    super(changeDetectorRef, elementRef, vcr, popperDefaults);
  }
  /** @nocollapse */
  static ɵfac = function NgxPopperjsLooseDirective_Factory(t) {
    return new (t || NgxPopperjsLooseDirective)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(NGX_POPPERJS_DEFAULTS));
  };
  /** @nocollapse */
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: NgxPopperjsLooseDirective,
    selectors: [["", "popperLoose", ""]],
    inputs: {
      popperLoose: "popperLoose",
      popperLoosePlacement: "popperLoosePlacement",
      popperLooseTrigger: "popperLooseTrigger"
    },
    exportAs: ["popperLoose"],
    standalone: true,
    features: [i0.ɵɵInheritDefinitionFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPopperjsLooseDirective, [{
    type: Directive,
    args: [{
      selector: "[popperLoose]",
      exportAs: "popperLoose",
      standalone: true
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.ViewContainerRef
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NGX_POPPERJS_DEFAULTS]
    }]
  }], {
    popperLoose: [{
      type: Input
    }],
    popperLoosePlacement: [{
      type: Input
    }],
    popperLooseTrigger: [{
      type: Input
    }]
  });
})();
var NgxPopperjsPlacements;
(function (NgxPopperjsPlacements) {
  NgxPopperjsPlacements["TOP"] = "top";
  NgxPopperjsPlacements["BOTTOM"] = "bottom";
  NgxPopperjsPlacements["LEFT"] = "left";
  NgxPopperjsPlacements["RIGHT"] = "right";
  NgxPopperjsPlacements["TOPSTART"] = "top-start";
  NgxPopperjsPlacements["BOTTOMSTART"] = "bottom-start";
  NgxPopperjsPlacements["LEFTSTART"] = "left-start";
  NgxPopperjsPlacements["RIGHTSTART"] = "right-start";
  NgxPopperjsPlacements["TOPEND"] = "top-end";
  NgxPopperjsPlacements["BOTTOMEND"] = "bottom-end";
  NgxPopperjsPlacements["LEFTEND"] = "left-end";
  NgxPopperjsPlacements["RIGHTEND"] = "right-end";
})(NgxPopperjsPlacements || (NgxPopperjsPlacements = {}));
function provideNgxPopperjsOptions(config = {}) {
  return [{
    provide: NGX_POPPERJS_DEFAULTS,
    useValue: config
  }];
}
class NgxPopperjsModule {
  static forRoot(popperBaseOptions) {
    return {
      ngModule: NgxPopperjsModule,
      providers: [provideNgxPopperjsOptions(popperBaseOptions)]
    };
  }
  /** @nocollapse */
  static ɵfac = function NgxPopperjsModule_Factory(t) {
    return new (t || NgxPopperjsModule)();
  };
  /** @nocollapse */
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: NgxPopperjsModule
  });
  /** @nocollapse */
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [provideNgxPopperjsOptions()],
    imports: [CommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPopperjsModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, NgxPopperjsContentComponent, NgxPopperjsDirective, NgxPopperjsLooseDirective],
      exports: [NgxPopperjsContentComponent, NgxPopperjsDirective, NgxPopperjsLooseDirective],
      providers: [provideNgxPopperjsOptions()]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-popperjs
 */
// Components

/**
 * Generated bundle index. Do not edit.
 */

export { NgxPopperjsContentComponent, NgxPopperjsDirective, NgxPopperjsLooseDirective, NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers, provideNgxPopperjsOptions };
