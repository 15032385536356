import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import {
  MatButton,
  MatIconAnchor,
  MatIconButton,
} from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'cat-ai-expand-content-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogClose,
    MatIcon,
    MatIconAnchor,
    MatIconButton,
    MatDialogContent,
    FormsModule,
    MatButton,
    MatDialogActions,
    MatFormField,
    MatInput,
  ],
  templateUrl: './expand-content-modal.component.html',
})
export class ExpandContentModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { title: string; content: string },
    public dialogRef: MatDialogRef<ExpandContentModalComponent>,
  ) {}

  clearData() {
    this.data.content = '';
  }

  closeModal() {
    this.dialogRef.close({ content: this.data.content });
  }
}
