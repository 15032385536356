<div class="{{ wrapperClasses }}">
  @if ({ expanded: true }; as scope) {
    <header
      class="py-4 px-6 flex justify-between items-center border border-gray-200 rounded-t-sm cursor-pointer"
      (click)="scope.expanded = !scope.expanded">
      <ng-container #customHeaderHitle>
        <ng-content select="[header]"></ng-content>
      </ng-container>

      @if (!customHeaderHitle.childNodes.length) {
        <h3>
          {{ title }}
        </h3>
      }

      <mat-icon
        data-testid="expand/minimize-btn"
        [class.rotate-180]="scope.expanded">
        expand_more
      </mat-icon>
    </header>

    <div
      class="p-6 flex flex-col gap-6 border-b border-r border-l border-gray-200 rounded-b-sm"
      [class.hidden]="!scope.expanded">
      <ng-content></ng-content>
    </div>
  }
</div>
