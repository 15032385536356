import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialRead } from '@cat-ai-us-fe/api';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DomSanitizer } from '@angular/platform-browser';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { VideoPlayerComponent } from '../video-player/video-player.component';
import { getFileExtension } from '@cat-ai-us-fe/shared/util';
import { DEFAULT_LABEL_SIZE } from 'bpmn-js/lib/util/LabelUtil';

@Component({
  selector: 'cat-ai-material-preview',
  standalone: true,
  imports: [
    CommonModule,
    PdfViewerModule,
    MatProgressSpinnerModule,
    VideoPlayerComponent,
  ],
  templateUrl: './material-preview.component.html',
})
export class MaterialPreviewComponent implements OnChanges {
  @Input() url!: string | undefined;
  @Input() loading = false;
  @Input() documentHeight: string | undefined = '100%';
  @Output() handleError = new EventEmitter();
  fileType: string | undefined;
  rendering = false;

  public MaterialType = MaterialRead.TypeEnum;
  constructor(protected _sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.url) {
      this.rendering = true;
      this.checkFileExtension();
    }
  }

  onSuccessRender() {
    this.rendering = false;
  }

  onError($event: any) {
    this.rendering = false;
    this.handleError.emit($event);
  }

  checkFileExtension(): void {
    this.rendering = false;
    if (this.url) {
      const fileExtension = getFileExtension(this.url);
      if (fileExtension) {
        this.fileType = fileExtension;
      }
    }
  }

  protected readonly DEFAULT_LABEL_SIZE = DEFAULT_LABEL_SIZE;
}
