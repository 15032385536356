<div
  data-testid="image-preview-popup-header"
  mat-dialog-header
  class="flex justify-between gap-3 px-6 py-5 items-center">
  <h2 data-testid="file-name">{{ data.fileName }}</h2>
  <div class="flex items-center gap-1">
    <a
      data-testid="download"
      [href]="data.url"
      download
      target="_blank"
      mat-icon-button>
      <mat-icon class="material-icons-outlined">download</mat-icon>
    </a>
    <button
      data-testid="close-btn"
      mat-icon-button
      mat-dialog-close
      type="button">
      <mat-icon class="material-icons-outlined">close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content class="!pt-0">
  <cat-ai-material-preview [url]="data.url" [documentHeight]="data.mediaHeight" />
</mat-dialog-content>
<mat-dialog-actions class="!justify-end">
  @if (!data.hideDelete) {
    <button
      data-testid="delete-btn"
      mat-button
      mat-dialog-close
      color="warn"
      type="button"
      class="w-[186px]"
      (click)="data.onDelete()">
      Delete
    </button>
  }
  <button
    data-testid="ok-btn"
    mat-flat-button
    color="accent"
    type="button"
    mat-dialog-close
    class="w-[186px]">
    Ok
  </button>
</mat-dialog-actions>
