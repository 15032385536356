import { Material, UsersService } from '@cat-ai-us-fe/api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { finalize, map, shareReplay } from 'rxjs';

export const filterFields = (apiService: UsersService): FormlyFieldConfig[] => [
  {
    wrappers: ['cat-ai-forms-divider'],
    fieldGroupClassName: 'grid grid-cols-3 gap-4 ',
    props: {
      label: 'Created',
    },
    fieldGroup: [
      {
        key: 'createdDateFrom',
        type: 'cat-ai-datepicker',
        className: 'col-span-2',
        props: {
          label: 'Date from',
          placeholder: 'dd.mm.yyyy',
          hideOptional: true,
        },
      },
      {
        key: 'createdTimeFrom',
        type: 'cat-ai-timepicker',
        props: {
          label: 'Time from',
          placeholder: '00:00',
          hideOptional: true,
        },
      },
      {
        key: 'createdDueDate',
        type: 'cat-ai-datepicker',
        className: 'col-span-2',
        props: {
          label: 'Due date',
          placeholder: 'dd.mm.yyyy',
          hideOptional: true,
        },
      },
      {
        key: 'createdDueTime',
        type: 'cat-ai-timepicker',
        props: {
          label: 'Due time',
          placeholder: '00:00',
          hideOptional: true,
        },
      },
    ],
  },
  {
    wrappers: ['cat-ai-forms-divider'],
    fieldGroupClassName: 'grid grid-cols-3 gap-4',
    props: {
      label: 'Updated',
    },
    fieldGroup: [
      {
        key: 'updatedDateFrom',
        type: 'cat-ai-datepicker',
        className: 'col-span-2',
        props: {
          label: 'Date from',
          placeholder: 'dd.mm.yyyy',
          hideOptional: true,
        },
      },
      {
        key: 'updatedTimeFrom',
        type: 'cat-ai-timepicker',
        props: {
          label: 'Time from',
          placeholder: '00:00',
          hideOptional: true,
        },
      },
      {
        key: 'updatedDueDate',
        type: 'cat-ai-datepicker',
        className: 'col-span-2',
        props: {
          label: 'Due date',
          placeholder: 'dd.mm.yyyy',
          hideOptional: true,
        },
      },
      {
        key: 'updatedDueTime',
        type: 'cat-ai-timepicker',
        props: {
          label: 'Due time',
          placeholder: '00:00',
          hideOptional: true,
        },
      },
    ],
  },
  {
    wrappers: ['cat-ai-forms-divider'],
    fieldGroupClassName: 'grid grid-rows-2 gap-4',
    props: {
      label: 'Info',
    },
    fieldGroup: [
      {
        key: 'createdBy',
        type: 'cat-ai-select',
        props: {
          label: 'Author',
          placeholder: 'Select document author',
          hideOptional: true,
        },
        hooks: {
          onInit: (field: any) => {
            field.props.loading = true;
            field.props.options = apiService
              .usersList({
                pageSize: 30,
              })
              .pipe(
                shareReplay(1),
                map((r) => r.results),
                map((r) =>
                  r.map((m) => {
                    return {
                      label: m.fullname || '-',
                      value: m.id,
                    };
                  }),
                ),
                finalize(() => (field.props.loading = false)),
              );
          },
        },
      },
      {
        key: 'status',
        type: 'cat-ai-select',
        props: {
          label: 'Status',
          placeholder: 'Select status',
          hideOptional: true,
          options: Object.values(Material.StatusEnum).map((priority) => ({
            label: priority,
            value: priority.toLowerCase(),
          })),
        },
      },
    ],
  },
];

export const searchFields: FormlyFieldConfig[] = [
  {
    key: 'search',
    type: 'cat-ai-input',
    props: {
      label: 'Search',
      placeholder: 'Type...',
      hideOptional: true,
    },
  },
];

export interface DocumentsFiltersForm {
  createdDateFrom?: string;
  createdTimeFrom?: string;
  createdDueDate?: string;
  createdDueTime?: string;
  updatedDateFrom?: string;
  updatedTimeFrom?: string;
  updatedDueDate?: string;
  updatedDueTime?: string;
  createdBy?: string;
  status?: string;
}
