/**
 * cat-ai-us API
 * API for Cat-AI-US project
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface CreateMaterialChatMessageRequest {
  text: string;
  material?: number;
  /**
   * * `DOCUMENT` - Document * `FLOWCHART` - Flowchart
   */
  material_type?: CreateMaterialChatMessageRequest.MaterialTypeEnum;
  attachments?: Array<number>;
  use_smart_context?: boolean;
  reply_to?: number;
  document_version?: string;
  flowchart_version?: string;
}
export namespace CreateMaterialChatMessageRequest {
  export type MaterialTypeEnum = 'DOCUMENT' | 'FLOWCHART';
  export const MaterialTypeEnum = {
    Document: 'DOCUMENT' as MaterialTypeEnum,
    Flowchart: 'FLOWCHART' as MaterialTypeEnum,
  };
}
