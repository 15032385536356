import { NgClass } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DocumentCardSkeletonComponent } from '../document-card-skeleton/document-card-skeleton.component';
import { RouterLink } from '@angular/router';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  NgxPopperjsModule,
  NgxPopperjsPlacements,
  NgxPopperjsTriggers,
  NgxPopperjsContentComponent,
} from 'ngx-popperjs';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import {
  DocumentsFiltersForm,
  filterFields,
  searchFields,
} from './sidebar-filters.config';
import { UsersService } from '@cat-ai-us-fe/api';
import { setDateWithTime } from '@cat-ai-us-fe/shared/util';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'cat-ai-documents-sidebar',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    NgClass,
    DocumentCardSkeletonComponent,
    RouterLink,
    InfiniteScrollDirective,
    MatProgressSpinnerModule,
    NgxPopperjsModule,
    FormlyModule,
  ],
  templateUrl: './documents-sidebar.component.html',
})
export class DocumentsSidebarComponent implements AfterViewInit {
  @Input({ required: true }) isLoading = false;
  @Input({ required: true }) isLoadingMore = false;
  @Input({ required: true }) addUrl = '';

  @Output() scrolled = new EventEmitter();
  @Output() applyFilters = new EventEmitter();
  @Output() applySearch = new EventEmitter();

  userService = inject(UsersService);

  public filtersForm = new FormGroup({});
  public filtersModel: DocumentsFiltersForm = {};
  public filtersfields: FormlyFieldConfig[] = filterFields(this.userService);

  public searchForm = new FormGroup({});
  public searchModel: DocumentsFiltersForm = {};
  public searchfields: FormlyFieldConfig[] = searchFields;

  public Object = Object;

  protected isCollapsed = false;
  protected readonly skeletonItems = Array.from({ length: 20 }).fill(undefined);
  protected readonly NgxPopperjsTriggers = NgxPopperjsTriggers;
  protected readonly NgxPopperjsPlacements = NgxPopperjsPlacements;

  ngAfterViewInit(): void {
    const searchControl = this.searchForm.get('search');
    if (!searchControl) {
      return;
    }
    searchControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe((value) => this.applySearch.emit({ title: value }));
  }

  toggleIsCollapsed() {
    this.isCollapsed = !this.isCollapsed;
  }

  hidePopover(filtersPopover: NgxPopperjsContentComponent) {
    filtersPopover.hide();
    this.clearFilters();
  }

  mapFilters(filtersPopover: NgxPopperjsContentComponent) {
    // todo: add type - interface DocumentsListRequestParams or flowchartslist
    const value = {};
    if (this.filtersModel.createdBy) {
      Object.assign(value, { createdBy: this.filtersModel.createdBy });
    }
    if (this.filtersModel.status) {
      Object.assign(value, { status: this.filtersModel.status });
    }
    if (this.filtersModel.createdDateFrom) {
      const createdAfter = setDateWithTime(
        this.filtersModel.createdDateFrom,
        this.filtersModel.createdTimeFrom,
      );
      Object.assign(value, { createdAfter });
    }
    if (this.filtersModel.createdDueDate) {
      const createdBefore = setDateWithTime(
        this.filtersModel.createdDueDate,
        this.filtersModel.createdDueTime,
      );
      Object.assign(value, { createdBefore });
    }
    if (this.filtersModel.updatedDateFrom) {
      const updatedAfter = setDateWithTime(
        this.filtersModel.updatedDateFrom,
        this.filtersModel.updatedTimeFrom,
      );
      Object.assign(value, { updatedAfter });
    }
    if (this.filtersModel.updatedDueDate) {
      const updatedBefore = setDateWithTime(
        this.filtersModel.updatedDueDate,
        this.filtersModel.updatedDueTime,
      );
      Object.assign(value, { updatedBefore });
    }
    this.applyFilters.emit(value);
    this.hidePopover(filtersPopover);
  }

  clearFilters() {
    this.filtersForm.reset();
  }
}
