<mat-dialog-content class="mat-typography">
  <div class="mb-2 flex items-center justify-between">
    <p class="text-m font-semibold">{{ data.modalTitle }}</p>
    <a class="cursor-pointer" mat-dialog-close>
      <mat-icon class="material-icons-outlined shrink-0 text-gray-700">
        close
      </mat-icon>
    </a>
  </div>

  <a class="cat-ai-document-card">
    <div class="cat-ai-document-card__header">
      <mat-icon class="-rotate-90 material-icons-outlined shrink-0">
        lan
      </mat-icon>
      <span class="text-sm font-normal grow pr-8">{{ data.title }}</span>
    </div>
    <div class="flex items-center justify-between">
      <div class="text-xs font-normal flex items-center gap-1">
        <span>
          {{ data.timestamp | dateFormat: DateFormatType.DocumentCardDate }}
        </span>
        <span class="cat-ai-document-card__divider text-gray-400">|</span>
        <span class="cat-ai-document-card__time text-gray-500">
          {{ data.timestamp | dateFormat: DateFormatType.DocumentCardTime }}
        </span>
      </div>

      <cat-ai-document-status [status]="data.status" [isInverted]="isActive" />
    </div>
  </a>
  <div class="social-section mt-[32px]">
    @for (item of sharedBy; track item) {
      <a
        class="inline-flex flex-col items-center"
        [href]="getLink()"
        target="_blank">
        <div
          class="bg-gray-100 rounded-full flex items-center justify-center size-[32px]">
          <mat-icon
            class="material-icons-outlined !size-[24px] text-gray-700 text-[24px]">
            {{ item.iconName }}
          </mat-icon>
        </div>
        <span class="mt-[12px]">{{ item.title }}</span>
      </a>
    }
  </div>
  <div class="flex items-center justify-center mt-[16px]">
    <div class="flex-grow border-t border-gray-300"></div>
    <span class="mx-2 text-gray-500">or</span>
    <div class="flex-grow border-t border-gray-300"></div>
  </div>
  <div class="mt-[16px]">
    <mat-form-field class="w-full mb-[12px]">
      <input
        type="text"
        matInput
        placeholder="URL Link"
        [value]="data.link"
        (click)="copyValue(data.link)"
        class="cursor-pointer"
        readonly />
      <mat-icon matSuffix>content_copy</mat-icon>
    </mat-form-field>
    <p class="text-xs text-gray-500">Link will expire in 24h</p>
  </div>
</mat-dialog-content>
