import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MaterialPreviewComponent } from '../material-preview/material-preview.component';

@Component({
  selector: 'cat-ai-image-preview-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MaterialPreviewComponent,
  ],
  templateUrl: './media-preview-dialog.component.html',
})
export class MediaPreviewDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      url: string;
      fileName: string;
      onDelete: () => void;
      hideDelete?: boolean;
      mediaHeight?: string;
    },
  ) {}

  protected readonly close = close;
}
