import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateFormatPipe, DateFormatType } from '@cat-ai-us-fe/shared/util';
import { DocumentStatusComponent } from '../document-status/document-status.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatDivider } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Material } from '@cat-ai-us-fe/api';

interface ControlModal {
  id: number;
  modalTitle: string;
  dialogType: string;
  title: string;
  timestamp: string;
  link: string;
  status: Material.StatusEnum;
}

@Component({
  selector: 'cat-ai-delete-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatIcon,
    MatDivider,
    MatFormFieldModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    DocumentStatusComponent,
    DateFormatPipe,
  ],
  templateUrl: './delete-modal.component.html',
})
export class DeleteModalComponent {
  protected isActive = false;
  protected readonly DateFormatType = DateFormatType;

  constructor(
    private dialogRef: MatDialogRef<DeleteModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ControlModal,
  ) {}

  removeDocument(id: number): void {
    this.dialogRef.close(id);
  }
}
