import { MaterialRead } from '@cat-ai-us-fe/api';

export function getMaterialUrl(material: MaterialRead) {
  switch (material.type) {
    case MaterialRead.TypeEnum.Document:
      if (!material.material_link?.document?.id) {
        return;
      }
      return `/generator/${material.material_link.document.id}`;
    case MaterialRead.TypeEnum.Form:
      if (!material.material_link?.filledform?.id) {
        return;
      }
      return `/records/${material.material_link.filledform.id}/filled`;
    case MaterialRead.TypeEnum.Flowchart:
      if (!material.material_link?.flowchart?.id) {
        return;
      }
      return `/flow-chart/${material.material_link.flowchart.id}`;
    case MaterialRead.TypeEnum.File:
    case MaterialRead.TypeEnum.Certificate:
      return `/dashboard/material/review/${material.id}`;
    default:
      return;
  }
}
