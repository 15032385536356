/**
 * cat-ai-us API
 * API for Cat-AI-US project
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface PatchedMaterialRequest {
  object_id?: number;
  title?: string | null;
  /**
   * * `created` - Created * `in_progress` - In Progress * `pending` - Pending * `approved` - Approved * `changes_requested` - Changes Requested * `rejected` - Rejected * `archived` - Archived * `uploaded` - Uploaded
   */
  status?: PatchedMaterialRequest.StatusEnum;
  description?: string | null;
  due_date?: string | null;
  is_approved?: boolean;
  /**
   * * `DOCUMENT` - Document * `FORM` - Form * `FILE` - File * `FLOWCHART` - Flowchart * `CERTIFICATE` - Certificate
   */
  type?: PatchedMaterialRequest.TypeEnum;
  change_request?: string;
  is_pinned?: boolean;
}
export namespace PatchedMaterialRequest {
  export type StatusEnum =
    | 'created'
    | 'in_progress'
    | 'pending'
    | 'approved'
    | 'changes_requested'
    | 'rejected'
    | 'archived'
    | 'uploaded';
  export const StatusEnum = {
    Created: 'created' as StatusEnum,
    InProgress: 'in_progress' as StatusEnum,
    Pending: 'pending' as StatusEnum,
    Approved: 'approved' as StatusEnum,
    ChangesRequested: 'changes_requested' as StatusEnum,
    Rejected: 'rejected' as StatusEnum,
    Archived: 'archived' as StatusEnum,
    Uploaded: 'uploaded' as StatusEnum,
  };
  export type TypeEnum =
    | 'DOCUMENT'
    | 'FORM'
    | 'FILE'
    | 'FLOWCHART'
    | 'CERTIFICATE';
  export const TypeEnum = {
    Document: 'DOCUMENT' as TypeEnum,
    Form: 'FORM' as TypeEnum,
    File: 'FILE' as TypeEnum,
    Flowchart: 'FLOWCHART' as TypeEnum,
    Certificate: 'CERTIFICATE' as TypeEnum,
  };
}
