/**
 * cat-ai-us API
 * API for Cat-AI-US project
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface PatchedUploadFileRequest {
  url?: string;
  /**
   * * `unspecified` - Unspecified * `header` - Header * `message_attachment` - Message Attachment
   */
  kind?: PatchedUploadFileRequest.KindEnum;
}
export namespace PatchedUploadFileRequest {
  export type KindEnum = 'unspecified' | 'header' | 'message_attachment';
  export const KindEnum = {
    Unspecified: 'unspecified' as KindEnum,
    Header: 'header' as KindEnum,
    MessageAttachment: 'message_attachment' as KindEnum,
  };
}
