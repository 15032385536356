@if (loading) {
  <mat-spinner [strokeWidth]="3" [diameter]="50" class="m-auto"></mat-spinner>
} @else {
  @if (training && user && certificate) {
    <div id="certificate_pdf" class="page-b5 bg-white p-6 m-auto">
      <div
        class="border h-full border-primary-300 m-auto p-6 flex flex-col gap-6 rounded-sm text-center justify-between">
        <header class="flex flex-col justify-center mb-2 items-center relative">
          <img
            data-testid="logo-img"
            [src]="user.company && user.company.logo"
            alt="company logo"
            class="h-14 w-20 object-cover self-start"
            crossorigin="anonymous" />
          <div class="text-center">
            <h1 data-testid="certificate-text" class="!text-4xl">
              CERTIFICATE
            </h1>
            <h2 data-testid="of-completion">OF COMPLETION</h2>
          </div>
          <img
            data-testid="certificate-qr-code-img"
            [src]="certificate.qr_code"
            alt=""
            class="absolute top-0 right-0 h-32 w-32"
            crossorigin="anonymous" />
        </header>
        <div class="pt-11 flex flex-col gap-3 items-center">
          <h3 data-testid="training-name-text">{{ training.name }}</h3>
          <h4 data-testid="user-fullname-text" class="!font-normal">
            {{ user.fullname }}
          </h4>
          <div class="flex gap-6">
            <div>
              @for (group of user.groups; track $index) {
                <p data-testid="group-name-text">{{ group.name }}</p>
              }
            </div>
            <div data-testid="job-category-name-text">
              {{ user.job_category && user.job_category.name }}
            </div>
          </div>
          <h5 data-testid="proficiency-text" class="max-w-[80%]">
            For successfully completing the training, demonstrating proficiency
            and a thorough understanding of
            <b data-testid="training-name">{{ training.name }}</b>
            .
          </h5>
          <div class="flex gap-6">
            <p data-testid="date-of-issue-text">
              Date of issue: {{ certificate.created_at | date: 'shortDate' }}
            </p>
            @if (certificate.valid_until) {
              <p data-testid="valid-date-text">
                Valid date: {{ certificate.valid_until | date: 'shortDate' }}
              </p>
            }
          </div>
        </div>
        <p data-testid="certificate-uuid-text">{{ certificate.uuid }}</p>
      </div>
    </div>
  }
}
