<div class="confirm-modal w-[396px] max-w-full">
  <h3 class="font-semibold pt-6 px-6 !mb-2">
    @switch (data.action) {
      @case ('approve') {
        Approve {{ data.materialType | titlecase }}
      }
      @case ('reject') {
        Reject {{ data.materialType | titlecase }}
      }
      @case ('request_changes') {
        Request changes
      }
      @default {
        Add feedback
      }
    }
  </h3>
  <p class="text-sm/[0.875rem] text-gray-500 px-6 !mb-6">
    @switch (data.action) {
      @case ('approve') {
        Add feedback about {{ data.materialType | lowercase }}
      }
      @case ('reject') {
        Describe rejection reason
      }
      @case ('request_changes') {
        Describe what changes need to be made
      }
      @default {}
    }
  </p>

  <mat-dialog-content>
    <mat-form-field class="w-full">
      <textarea
        matInput
        placeholder="Write a feedback"
        [formControl]="reviewControl"
        rows="4"></textarea>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button
      mat-flat-button
      color="basic"
      [mat-dialog-close]="false"
      class="flex-1">
      Cancel
    </button>
    <button
      mat-flat-button
      [color]="data.action === 'reject' ? 'warn' : 'accent'"
      [mat-dialog-close]="reviewControl.value"
      class="flex-1"
      [disabled]="!reviewControl.value">
      {{
        data.action === 'request_changes'
          ? 'Send Request'
          : (data.action | titlecase)
      }}
    </button>
  </mat-dialog-actions>

  <button
    type="button"
    mat-icon-button
    [mat-dialog-close]="false"
    class="!absolute top-2 right-1">
    <mat-icon class="text-gray-500">close</mat-icon>
  </button>
</div>
