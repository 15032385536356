import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Material } from '@cat-ai-us-fe/api';

@Component({
  selector: 'cat-ai-document-status',
  standalone: true,
  imports: [NgClass],
  templateUrl: './document-status.component.html',
})
export class DocumentStatusComponent {
  @Input({ required: true }) status: Material.StatusEnum =
    Material.StatusEnum.Created;
  @Input() isInverted = false;

  getStatusClass() {
    switch (this.status) {
      case Material.StatusEnum.Pending:
        return 'cat-ai-document-status_in-progress';
      case Material.StatusEnum.Approved:
        return 'cat-ai-document-status_success';
      case Material.StatusEnum.Created:
      case Material.StatusEnum.Archived:
      case Material.StatusEnum.InProgress:
      case Material.StatusEnum.Uploaded:
        return this.isInverted
          ? `cat-ai-document-status_draft-inverted`
          : 'cat-ai-document-status_draft';
      case Material.StatusEnum.Rejected:
      case Material.StatusEnum.ChangesRequested:
        return 'cat-ai-document-status_fail';
      default:
        return '';
    }
  }

  getFormattedStatus() {
    if (this.status === Material.StatusEnum.Created) {
      return 'Draft';
    }
    if (this.status === Material.StatusEnum.ChangesRequested) {
      return 'Change Request';
    }
    return this.status.replace('_', ' ').toLowerCase();
  }
}
