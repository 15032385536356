/**
 * cat-ai-us API
 * API for Cat-AI-US project
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ShallowUser } from './shallow-user';

export interface MaterialValidation {
  readonly id: number;
  material: number;
  readonly validated_at: string | null;
  readonly created_at: string;
  readonly created_by: ShallowUser;
  readonly validated_by: ShallowUser;
  /**
   * * `pending` - Pending * `approved` - Approved * `changes_requested` - Changes Requested * `rejected` - Rejected
   */
  readonly status: MaterialValidation.StatusEnum;
  reason?: string | null;
  readonly review: string | null;
  suggestions?: Array<string> | null;
}
export namespace MaterialValidation {
  export type StatusEnum =
    | 'pending'
    | 'approved'
    | 'changes_requested'
    | 'rejected';
  export const StatusEnum = {
    Pending: 'pending' as StatusEnum,
    Approved: 'approved' as StatusEnum,
    ChangesRequested: 'changes_requested' as StatusEnum,
    Rejected: 'rejected' as StatusEnum,
  };
}
