import { Component, DestroyRef, inject, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateFormatPipe, DateFormatType } from '@cat-ai-us-fe/shared/util';
import { DocumentStatusComponent } from '../document-status/document-status.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Material, MaterialsService } from '@cat-ai-us-fe/api';
import { finalize } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface ControlModal {
  id?: number;
  modalTitle: string;
  dialogType: string;
  title: string;
  timestamp: string;
  materialId: number;
  status: Material.StatusEnum;
}

@Component({
  selector: 'cat-ai-duplicate-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatIcon,
    MatFormFieldModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    DocumentStatusComponent,
    DateFormatPipe,
  ],
  templateUrl: './duplicate-modal.component.html',
})
export class DuplicateModalComponent {
  protected isActive = false;
  protected readonly DateFormatType = DateFormatType;
  protected isLoading = false;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ControlModal,
    private readonly materialsService: MaterialsService,
    public dialogRef: MatDialogRef<DuplicateModalComponent>,
  ) {}

  duplicate() {
    this.isLoading = true;

    this.materialsService
      .materialsDuplicateCreate({
        id: this.data.materialId,
        shareMaterialRequest: {
          keep_chat_history: this.isActive,
        },
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }
}
