<div
  data-testid="file-conteiner"
  class="relative rounded-sm overflow-hidden bg-white w-fit"
  (mouseenter)="changeControlsView(true)"
  (mouseleave)="changeControlsView(false)">
  @switch (mediaData.type) {
    @case ('image') {
      <div
        data-testid="image-file"
        class="w-[95px] h-[70px] border-2 border-gray-25">
        @if (mediaData.src) {
          <img [ngSrc]="mediaData.src" alt="" fill class="object-cover" />
        }
      </div>
    }
    @case ('document') {
      <div
        data-testid="document-file"
        class="w-[129px] h-[70px] cat-badge-status-active flex-col px-2 py-4">
        <div class="flex items-center w-full h-4">
          <mat-icon class="material-icons-outlined !size-4 !text-xs">
            insert_drive_file
          </mat-icon>
          <p class="text-xs truncate w-full">
            {{ mediaData.name }}
          </p>
        </div>
        @if (mediaData.size) {
          <p class="!mt-2">
            {{ mediaData.size | convertBytes: 'KB' | number: '.0-2' }} KB
          </p>
        }
      </div>
    }
  }
  <ng-container *ngTemplateOutlet="controlPanel" />
</div>

<ng-template #controlPanel>
  @if (showControls) {
    <div
      class="absolute top-0 bottom-0 left-0 right-0 bg-white/85 flex flex-col items-center border-gray-100 border-2 rounded-sm">
      <div class="flex items-center content-center pt-2">
        @if (
          mediaData.status === 'uploading' || mediaData.status === 'parsing'
        ) {
          <mat-progress-spinner
            data-testid="uploading-spinner"
            color="primary"
            diameter="18"
            mode="indeterminate"
            class="mb-3" />
        } @else {
          @if (mediaData.status !== 'failed') {
            <button
              data-testid="image-preview-button"
              mat-fab
              type="button"
              color="accent"
              class="help-center-btn mr-1"
              (mouseenter)="updateStatusMessage(MediaActionMessage.preview)"
              (mouseleave)="updateStatusMessage('')"
              (click)="action.emit('preview')">
              <mat-icon>aspect_ratio</mat-icon>
            </button>
          }
          @if (!disableDelete) {
            <button
              data-testid="image-delete-button"
              mat-fab
              type="button"
              color="warn"
              class="help-center-delete-btn"
              (mouseenter)="updateStatusMessage(MediaActionMessage.delete)"
              (mouseleave)="updateStatusMessage('')"
              (click)="action.emit('delete')">
              <mat-icon class="material-icons-outlined">delete</mat-icon>
            </button>
          }
        }
      </div>
      @if (statusMessage) {
        <p class="text-gray-700 mt-2 text-center">
          {{ statusMessage }}
          @if (mediaData.status === 'failed' && mediaData.errorMsg) {
            <mat-icon
              class="material-icons-outlined text-red-500 !w-[11px] !h-[10px] text-xs/[11px]"
              [matTooltip]="mediaData.errorMsg">
              info
            </mat-icon>
          }
        </p>
      }
    </div>
  }
</ng-template>
