import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ReviewModalConfig } from './review-modal.interface';
import { LowerCasePipe, TitleCasePipe } from '@angular/common';

@Component({
  selector: 'cat-ai-review-modal',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    TitleCasePipe,
    LowerCasePipe,
  ],
  templateUrl: './review-modal.component.html',
})
export class ReviewModalComponent {
  public reviewControl = new FormControl('');
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ReviewModalConfig,
  ) {}
}
