<div class="flex items-center justify-between py-3 px-6">
  <button
    data-testid="previous"
    mat-flat-button
    color="basic"
    (click)="changePage(pagination.page - 1)"
    [disabled]="pagination.page === 1 || isLoading"
    class="shadow-xs">
    Previous
    <mat-icon>arrow_back</mat-icon>
  </button>

  <!-- PAGINATION START -->
  <div>
    <cat-ai-pagination-button
      [hidden]="
        pagesCount <= pagesDisplayCount ||
        pagination.page <= pagesDisplayCount - 1
      "
      (click)="changePage(step)"
      [disabled]="isLoading">
      1
    </cat-ai-pagination-button>
    <cat-ai-pagination-button
      [hidden]="
        pagesCount <= pagesDisplayCount ||
        pagination.page <= pagesDisplayCount - 1
      "
      (click)="changePage(pagination.page - step)"
      [disabled]="isLoading">
      ...
    </cat-ai-pagination-button>
    @for (page of pagesButtons; track page) {
      <cat-ai-pagination-button
        data-testid="pagination-button"
        [active]="page === pagination.page"
        (click)="changePage(page)"
        [disabled]="isLoading">
        {{ page }}
      </cat-ai-pagination-button>
    }
    <cat-ai-pagination-button
      [hidden]="
        pagesCount <= pagesDisplayCount ||
        pagination.page > pagesCount - pagesDisplayCount / 2
      "
      (click)="changePage(pagination.page + step)"
      [disabled]="isLoading">
      ...
    </cat-ai-pagination-button>
    <cat-ai-pagination-button
      [hidden]="
        pagesCount <= pagesDisplayCount || pagesCount - pagination.page <= step
      "
      (click)="changePage(pagesCount)"
      [disabled]="isLoading">
      {{ pagesCount }}
    </cat-ai-pagination-button>
  </div>
  <!-- PAGINATION END -->
  <button
    data-testid="next-btn"
    mat-flat-button
    color="basic"
    (click)="changePage(pagination.page + 1)"
    [disabled]="
      pagination.page === pagesCount || !pagesButtons.length || isLoading
    "
    class="shadow-xs">
    Next
    <mat-icon iconPositionEnd class="material-icons-outlined">
      arrow_forward
    </mat-icon>
  </button>
</div>
