/* Enum values must use angular date pipe format options - https://angular.io/api/common/DatePipe#custom-format-options
HTML characters like comma, space should be in format of HTML enity
*/
export enum DateFormatType {
  DayMonthYear = `dd/MM/YYYY`,
  /* outcome example: 26 March, 2024 */
  DayMonthYearLinear = `d LLLL yyyy`,
  DayMonthYearLinearShort = `d LLL, yyyy`,
  /* outcome example: March 26, 24 | 12:05pm */
  MonthDayYearLineHour = `LLLL d, yy | h:mm a`,
  MonthDay = `MMM d`,
  MonthDayYear = 'M/dd/YYYY',
  /* outcome example: March 26, 24 */
  MonthDateYear = 'LLLL d, yy',
  YearMonthDay = 'YYYY-MM-dd',
  MonthDateYearAlt = 'MMM d, yyyy',
  /* outcome example: 12.02.2025 */
  DocumentCardDate = 'dd.MM.YYYY',
  /* outcome example: 5:04 PM */
  DocumentCardTime = 'h:mm a',
}
