<div class="h-full px-6 flex items-center bg-white">
  <button
    type="button"
    mat-icon-button
    class="mr-3"
    (click)="backClick.emit()"
    title="Close">
    <mat-icon>close</mat-icon>
  </button>
  <span class="font-semibold text-2xl grow">{{ title }}</span>
  <div>
    <ng-content />
  </div>
</div>
