/**
 * cat-ai-us API
 * API for Cat-AI-US project
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface PatchedDocumentVersionRequest {
  document?: number;
  document_body?: string | null;
  custom_fields?: any | null;
  title?: string | null;
  /**
   * * `created` - Created * `updated` - Updated * `not_updated` - Not Updated
   */
  reason?: PatchedDocumentVersionRequest.ReasonEnum | null;
  category?: number | null;
  form_number?: number | null;
}
export namespace PatchedDocumentVersionRequest {
  export type ReasonEnum = 'created' | 'updated' | 'not_updated' | 'null';
  export const ReasonEnum = {
    Created: 'created' as ReasonEnum,
    Updated: 'updated' as ReasonEnum,
    NotUpdated: 'not_updated' as ReasonEnum,
    Null: 'null' as ReasonEnum,
  };
}
