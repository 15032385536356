<div
  class="relative h-full border-r border-gray-200 bg-white transition-[width] flex flex-col"
  [ngClass]="{
    'w-[300px]': !isCollapsed,
    'w-[40px]': isCollapsed,
  }">
  @if (!isLoading) {
    <button
      type="button"
      mat-mini-fab
      color="basic"
      class="square !absolute top-[84px] right-0 translate-x-1/2 [--mdc-icon-button-state-layer-size:32px] z-50"
      (click)="toggleIsCollapsed()">
      @if (isCollapsed) {
        <mat-icon>chevron_right</mat-icon>
      } @else {
        <mat-icon>chevron_left</mat-icon>
      }
    </button>
  }
  @if (!isCollapsed) {
    <div
      class="h-[68px] flex justify-between items-center px-4 border-b border-gray-200 shrink-0">
      <button
        [popper]="filtersPopover"
        [popperTrigger]="NgxPopperjsTriggers.click"
        [popperPlacement]="NgxPopperjsPlacements.BOTTOMSTART"
        [popperStyles]="{ width: '380px' }"
        popperHideOnClickOutside="false"
        type="button"
        mat-mini-fab
        class="square"
        [disabled]="isLoading"
        color="basic">
        <mat-icon [color]="filtersPopover.state ? 'primary' : ''">
          filter_alt
        </mat-icon>
      </button>
      <div class="flex items-center gap-2">
        <button
          [popper]="searchPopover"
          [popperTrigger]="NgxPopperjsTriggers.click"
          [popperPlacement]="NgxPopperjsPlacements.BOTTOM"
          [popperStyles]="{ width: '380px' }"
          type="button"
          mat-mini-fab
          class="square"
          color="basic">
          <mat-icon>search</mat-icon>
        </button>

        <button
          type="button"
          mat-mini-fab
          class="square"
          color="accent"
          [routerLink]="addUrl"
          [disabled]="isLoading">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
    <div
      class="flex flex-col gap-2 p-1 overflow-y-auto"
      infiniteScroll
      [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="1000"
      (scrolled)="scrolled.emit()"
      [scrollWindow]="false">
      @if (isLoading) {
        @for (item of skeletonItems; track $index) {
          <cat-ai-document-card-skeleton />
        }
      } @else {
        <ng-content />
        @if (isLoadingMore) {
          <div class="flex flex-col gap-4 items-center pt-2">
            <mat-spinner class="!size-8" />
            <span class="text-gray-700 text-sm leading-none">
              Loading more...
            </span>
          </div>
        }
      }
    </div>
  }
</div>

<popper-content #filtersPopover>
  <div
    class="fixed top-0 right-0 left-0 bottom-0 z-0"
    (click)="hidePopover(filtersPopover)"></div>
  <div class="flex flex-col gap-4 p-3.5 z-10 relative">
    <formly-form
      [form]="filtersForm"
      [fields]="filtersfields"
      [model]="filtersModel"></formly-form>
    <div class="self-end flex gap-4">
      <button
        type="button"
        mat-flat-button
        color="warn"
        class="outlined"
        (click)="hidePopover(filtersPopover)">
        Cancel
      </button>
      <button
        type="button"
        mat-flat-button
        color="accent"
        [disabled]="!Object.values(filtersModel).length"
        (click)="mapFilters(filtersPopover)">
        Apply Filters
      </button>
    </div>
  </div>
</popper-content>

<popper-content #searchPopover>
  <div class="flex flex-col gap-4 p-3.5">
    <formly-form
      [form]="searchForm"
      [fields]="searchfields"
      [model]="searchModel"></formly-form>
    <button
      type="button"
      mat-flat-button
      color="warn"
      class="outlined self-end"
      (click)="searchPopover.hide()">
      Cancel
    </button>
    <ng-content select="[searchResults]"></ng-content>
  </div>
</popper-content>
