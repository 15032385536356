import { Component, Inject, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DateFormatType, DateFormatPipe } from '@cat-ai-us-fe/shared/util';
import { Material } from '@cat-ai-us-fe/api';
import { Clipboard } from '@angular/cdk/clipboard';
import { shareBy } from './share-modal.config';
import { DocumentStatusComponent } from '../document-status/document-status.component';

interface ControlModal {
  id: number;
  modalTitle: string;
  dialogType: string;
  title: string;
  timestamp: string;
  link: string;
  status: Material.StatusEnum;
}

@Component({
  selector: 'cat-ai-share-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatIcon,
    MatFormFieldModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    DateFormatPipe,
    DocumentStatusComponent,
  ],
  templateUrl: './share-modal.component.html',
})
export class ShareModalComponent {
  protected isActive = false;
  protected readonly DateFormatType = DateFormatType;
  private copy = inject(Clipboard);

  sharedBy = shareBy;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ControlModal,
  ) {}

  copyValue(value: string) {
    this.copy.copy(value);
  }

  getLink() {
    const url = new URL('mailto:example@mail.com');
    url.searchParams.append('body', this.data.link);
    return url.toString();
  }
}
