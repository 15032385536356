<div class="flex justify-between gap-3 px-6 pt-5 items-center">
  <h2>{{ data.title }}</h2>
  <button
    mat-icon-button
    type="button"
    data-testid="close-content-modal"
    mat-dialog-close>
    <mat-icon class="material-icons-outlined">fullscreen_exit</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <mat-form-field class="w-full !pt-0">
    <textarea
      matInput
      class="w-full"
      data-testid="content-modal-field"
      [(ngModel)]="data.content"
      rows="16"></textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions class="!justify-end">
  <button
    mat-button
    color="basic"
    type="button"
    class="w-[186px]"
    data-testid="content-modal-clear-btn"
    (click)="clearData()">
    Clear
  </button>
  <button
    mat-flat-button
    color="accent"
    type="button"
    class="w-[186px]"
    data-testid="content-modal-ok-btn"
    (click)="closeModal()">
    Ok
  </button>
</mat-dialog-actions>
